import styled from "styled-components/native";
import { typography, color, space, position, variant, flex, } from "styled-system";
import fonts from "../Theme/fonts";
const Text = styled.Text.attrs((props) => {
    return {
        dataSet: {
            usageId: props["data-usage-id"],
            usageAction: props["data-usage-action"],
        },
    };
}) `
  ${typography}
  ${color}
  ${space}
  ${position}
  ${flex}
  ${variant({
    variants: fonts,
})}
`;
export default Text;
