import { Modal } from "@src/components";
import { BankAccountDetailsResponse, UpdateDefaultPaymentAccountData } from "@src/types/PaymentPreferences";
import { useTranslation } from "react-i18next";
import { useBranding } from "@src/navigation/useBranding";
import { useGlobalTenant } from "@src/navigation/hooks/useGlobalTenant";
import { useDownloadFile } from "@src/hooks/useDownloadFile/useDownloadFile";
import useKolmeoAuth from "@src/lib/AzureAuth/useKolmeoAuth";
import { fileTypeLookupCodeMapping } from "@src/types/constants/fileTypes";
import { useEffect, useState } from "react";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import { useUpdateDefaultBankAccountMutation } from "@src/screens/Tenant/hooks/usePaymentPreferences";
import PaymentMethodContainer from "./components/PaymentMethodContainer";
import PaymentMethodCallToAction from "./components/PaymentMethodCallToAction";
import { PaymentMethod, PaymentMethodCodes } from "@src/types";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { MessageBar } from "@kolmeo/ui-components";

type ViewPaymentMethodFormType = {
  isVisible: boolean;
  toggleVisibility: (toggleTrue: boolean) => void;
  item?: BankAccountDetailsResponse;
  detailsItem?: TenancyPaymentDetails;
  paymentMethod?: PaymentMethodCodes;
  propertyName?: string;
  handleRefetch: () => void;
  onDeleteAccountPress: () => void;
  disableDeactivation: boolean;
  disableSetDefault: boolean;
  disableEditNickname: boolean;
  hideCTA: boolean;
  isReloading: boolean;
};

const PaymentMethodModal = ({
  isVisible,
  toggleVisibility,
  item,
  detailsItem,
  paymentMethod,
  propertyName,
  handleRefetch,
  onDeleteAccountPress,
  disableDeactivation,
  disableSetDefault,
  disableEditNickname,
  hideCTA,
  isReloading
}: ViewPaymentMethodFormType) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { brandingObject } = useBranding();
  const { tenancyId } = useGlobalTenant();
  const { spacing } = useTheme();
  const [showMessageBar, setShowMessageBar] = useState(false);

  useEffect(() => {
    setShowMessageBar(false);
  }, [isVisible]);

  const { mutateAsync, isLoading, isSuccess } = useUpdateDefaultBankAccountMutation();
  // this logic should be revisted if we want the user to open the file without downloading
  // const { handlePreview } = useAuthorityDownload({
  //   fileType: item?.debitAuthorityDocumentDetails?.fileTypeCode
  //     ? fileTypeLookupCodeMapping[item.debitAuthorityDocumentDetails?.fileTypeCode]
  //     : item?.debitAuthorityDocumentDetails?.fileExtension?.replace(".", ""),
  //   mime:
  //     item?.debitAuthorityDocumentDetails && isImageFileTypeByCode(item?.debitAuthorityDocumentDetails?.fileTypeCode)
  //       ? `image/${item?.debitAuthorityDocumentDetails?.fileTypeCode}`
  //       : "application/pdf",
  //   documentUrl: item?.debitAuthorityDocumentDetails?.url ?? "",
  //   name: `debit-authority-${item?.accountId}`
  // });
  const { download: downloadDocument } = useDownloadFile();
  const { getBearerToken } = useKolmeoAuth();

  const handleSetToDefaultPress = async () => {
    const form: UpdateDefaultPaymentAccountData = { accountId: item?.accountId ?? 0, tenancyId: tenancyId ?? 0 };
    mutateAsync(form, {
      onSuccess: () => {
        setShowMessageBar(true);
        handleRefetch();
      },
      onError: () => console.log("error")
    });
  };
  const handleDownloadDocument = () => {
    if (item?.debitAuthorityDocumentDetails) {
      downloadDocument({
        fileUrl: item?.debitAuthorityDocumentDetails?.url ?? "",
        fileName: item?.debitAuthorityDocumentDetails.name,
        authToken: getBearerToken(),

        fileType:
          fileTypeLookupCodeMapping[item.debitAuthorityDocumentDetails.fileTypeCode] ??
          item.debitAuthorityDocumentDetails?.fileExtension?.replace(".", "")
      });
    } else {
      console.log(item, `no debit authority document details`);
    }
  };

  const handleDefaultActionPress = () => {
    toggleVisibility(false);
  };

  const getHeader = (paymentMethod: PaymentMethodCodes | undefined) => {
    switch (paymentMethod) {
      case PaymentMethod.debitNow:
      case PaymentMethod.directDebit:
        return t("Tenant:PaymentMethods.BankAccountDetailsHeader");
      case PaymentMethod.virtualAccount:
        return t("Tenant:PaymentMethods.VirtualAccountDetailsHeader");
      case PaymentMethod.bpay:
      default:
        return t("Tenant:PaymentMethods.BPAYDetailsHeader");
    }
  };

  return (
    <Modal
      touchToDismiss
      toggleVisible={toggleVisibility}
      isVisible={isVisible}
      title={getHeader(paymentMethod)}
      renderHeaderSeperator
      callToAction={
        <PaymentMethodCallToAction
          hideCta={hideCTA}
          disableSetDefault={item?.isDefault == true || disableSetDefault}
          onDeleteAccountPress={onDeleteAccountPress}
          disableDeactivation={disableDeactivation}
          isLoading={isLoading}
          brandingObject={brandingObject}
          handleSetToDefaultPress={handleSetToDefaultPress}
          handleDefaultActionPress={handleDefaultActionPress}
        />
      }
    >
      {paymentMethod === PaymentMethod.virtualAccount ? (
        <Box flexDirection="column" marginBottom={spacing.px16} marginLeft={spacing.px20} marginRight={spacing.px20}>
          <Text variant="label14Regular">{t("Tenant:PaymentMethods.VirtualAccountDetailsUsage1")}</Text>
        </Box>
      ) : (
        <></>
      )}
      <PaymentMethodContainer
        isReloading={isReloading}
        handleRefetch={handleRefetch}
        handleDownloadDocument={handleDownloadDocument}
        isSuccess={isSuccess}
        showMessageBar={showMessageBar}
        disableEditNickname={disableEditNickname}
        item={item}
        paymentMethod={paymentMethod}
        detailsItem={detailsItem}
        propertyName={propertyName}
      />
      {paymentMethod === PaymentMethod.virtualAccount || paymentMethod === PaymentMethod.bpay ? (
        <Box flexDirection="row" marginTop={spacing.px16} marginLeft={spacing.px20} marginRight={spacing.px20}>
          <MessageBar width="100%" type="info" title={t("Tenant:PaymentMethods.VirtualAccountDetailsInfo")} />
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default PaymentMethodModal;
