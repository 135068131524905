import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { TouchableOpacity as RNTouchableOpacity, Platform, } from "react-native";
import styled from "styled-components/native";
import { color, space, layout, position, border, flexbox, boxShadow, variant, } from "styled-system";
const StyledTouchableOpacity = styled(RNTouchableOpacity).attrs((props) => ({
    dataSet: {
        usageId: props["data-usage-id"],
        usageAction: props["data-usage-action"],
    },
})) `
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${boxShadow}
  ${variant({
    variants: {
        primary: {
            color: "white",
            bg: "primary",
        },
        secondary: {
            color: "white",
            bg: "secondary",
        },
    },
})}
`;
const TouchableOpacity = ({ hoverStyle = {}, extraWebElements = {}, onHoverIn = () => { }, onHoverOut = () => { }, ...props }) => {
    const componentRef = useRef(null);
    useEffect(() => {
        if (componentRef.current && extraWebElements) {
            componentRef.current.setNativeProps(extraWebElements);
        }
    }, [extraWebElements]);
    const handleHoverIn = () => {
        onHoverIn();
        if (hoverStyle && componentRef.current) {
            componentRef.current.setNativeProps({ style: hoverStyle });
        }
    };
    const handleHoverOut = () => {
        onHoverOut();
        if (componentRef.current) {
            componentRef.current.setNativeProps({ style: {} });
        }
    };
    return (_jsx(StyledTouchableOpacity, { ...props, ref: componentRef, ...(Platform.OS === "web" && {
            onMouseEnter: handleHoverIn,
            onMouseLeave: handleHoverOut,
        }) }));
};
export default TouchableOpacity;
