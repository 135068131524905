import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { Feature, useGetFeatures } from "./useGetFeatures";
import { useAuth } from "react-oidc-context";

type FeatureFlags = {
  features: Feature[];
  isLoading: boolean;
  setFeatures?: (features: Feature[]) => void;
};

export const FeatureFlagsContext = createContext<FeatureFlags>({
  features: [] as Feature[],
  isLoading: true
} as FeatureFlags);

export const FeatureFlagsProvider = ({ children }: React.PropsWithChildren) => {
  const { isAuthenticated } = useAuth();
  const [root, tenant] = useGetFeatures(isAuthenticated);
  const [features, setFeatures] = useState<Feature[]>([]);
  const isLoading = root.isLoading || tenant.isLoading;

  useEffect(() => {
    if (root.data && tenant.data) {
      const newFeatures = [...root.data, ...tenant.data];
      setFeatures(newFeatures);
    }
  }, [root.data, tenant.data]);

  const contextValue = useMemo(
    () => ({
      features,
      isLoading,
      setFeatures
    }),
    [features, isLoading]
  );

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const useFeature = (tag: string) => {
  const { features } = useContext(FeatureFlagsContext);
  return useMemo(() => features?.find((item: Feature) => item.id === tag), [features, tag]);
};

export const useUpdateFeatureFlags = () => {
  const context = useFeatureFlags();
  if (!context.setFeatures) {
    throw new Error("setFeatures is not defined in the context");
  }

  const updateFeatureFlags = (newFeatures: Feature[]) => {
    if (context.setFeatures) context.setFeatures(newFeatures);
  };

  return updateFeatureFlags;
};
